import {SetStateAction, useState} from 'react'
import { LandingPageProps } from '../interfaces/Searches';
import { Link } from 'react-router-dom';

export default function LandingPage( {searchQuery, setSearchQuery} : LandingPageProps ) {
    const [responseArticle, setResponseArticle] = useState("");
    const [headlines, setHeadlines] = useState<string[]>([]);
    const [generationInProgress, setGenerationInProgress] = useState(false);
    const [selectedHeadline, setSelectedHeadline] = useState<string | null>(null);
    const handleInputChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setSearchQuery(e.target.value);
    };
    
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setHeadlines([]);
        if (generationInProgress) {
            return;
        }
        setGenerationInProgress(true);
        const url = `https://quidam-a6niq4wkvq-uc.a.run.app/getHeadlines?name=${searchQuery}`;
        // const url = `http://localhost:5005/getHeadlines?name=${searchQuery}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: searchQuery }),
        })

        const decoder = new TextDecoder();

        // Set up a new ReadableStream to read the response body
        const reader = response.body!.getReader();
        let chunks = "";

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const decoded = decoder.decode(value);
            chunks += decoded;
            // here, we split the response into an array of strings, each of which is a headline
            const headlines = chunks.split("\n");
            setHeadlines(headlines);
        }
        setGenerationInProgress(false);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevents the newline
            handleSubmit({ preventDefault: () => {} }); // Manually trigger form submission
        }
    };

    const handleCreateArticle = async (chosenHeadline: string) => {
        setSelectedHeadline(chosenHeadline);
        if (generationInProgress) {
            return;
        }
        setGenerationInProgress(true);
        const url = `https://quidam-a6niq4wkvq-uc.a.run.app/getFullArticle?headline=${chosenHeadline}&context=${searchQuery}`;
        // const url = `http://localhost:5005/getFullArticle?headline=${chosenHeadline}&context=${searchQuery}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ headline: chosenHeadline}),
        })

        const decoder = new TextDecoder();

        // Set up a new ReadableStream to read the response body
        const reader = response.body!.getReader();
        let chunks = "";

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const decoded = decoder.decode(value);
            chunks += decoded;
            setResponseArticle(chunks);
        }
        setGenerationInProgress(false);
    }

    return (
        
        <div className='w-full md:w-3/4 m-auto h-full font-serif'>

        <div className='p-2 grid grid-cols-1'>
            {/* <div className='p-2 border-4 border-red-950 h-[90vh] rounded-sm bg-orange-100 border-opacity-80'>
                <div className='p-2 border-4 border-red-950 h-full rounded-lg border-opacity-50'>
                    <div className='p-2 border-2 border-red-950 h-full rounded-lg border-opacity-50'> */}
                        <div className='pt-16 pb-8'>
                        <h1 className="text-center font-nyt_headline text-5xl md:text-8xl">Don't be a Quidam</h1>
                        </div>
                        {/* <hr className='border-2 border-red-950 rounded-lg border-opacity-50'/> Add the horizontal line here */}
                        <div className="p-2 grid grid-cols-1 md:grid-cols-2 gap-16">
                            {/* left column */}
                            <div className='grid-cols-1'> 
                                <div className='block md:hidden'>
                                    <h1 className="text-2xl text-center font-bold pb-4">Personalized Fun with Just a Click of a Button!</h1>
                                    <hr className='border-1 rounded-lg border-slate-900'/>
                                    <h1 className="text-xl text-start py-4">
                                        Quidam is the perfect entertaining gift for you and your friends. 
                                        Type in your names and it'll create personalized articles for some good-natured humor. 
                                        Plus, you can generate a quirky newsletter to share the laughs with everyone!
                                    </h1>
    
                                </div>
                                <div className="pb-4">
                                    <img src='/img/ai_news_2.png' alt="Newspaper" className="max-w-full h-auto rounded-lg object-contain" />
                                </div> 

                                <div id="Generate" className="col-span-1">
                                    <form onSubmit={handleSubmit} className="p-4">
                                        <div className="flex-col">
                                            <label htmlFor="text" className="block font-bold text-2xl md:text-3xl mb-3">
                                                Who should we de-quidam-ify?
                                            </label>
                                          
                                            <div className="flex place-content-center">
                                                <textarea
                                                    id="text"
                                                    name="text"
                                                    value={searchQuery}
                                                    placeholder="Amr Kayid and Sylvie Shi"
                                                    onChange={handleInputChange}
                                                    onKeyDown={handleKeyDown} 
                                                    className="w-3/4 p-1.5 font-semibold text-lg md:text-xl bg-transparent outline-none border-b-4 rounded-sm hover:border-black"
                                                    required
                                                    rows={1} // You can adjust the number of rows as needed
                                                ></textarea>
                                                <button type="submit" className="bg-transparent rounded-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-8 h-8">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {/* Generated headlines */}
                                <div className="grid grid-cols-1">
                                    {headlines.length > 0 && headlines.map((headline_text, index) => (
                                    <div className={`rounded-lg bg-orange-300 hover:bg-orange-400 hover:scale-105 hover:cursor-pointer mb-4 md:mb-0 border p-2 m-2 ${selectedHeadline === headline_text ? 'bg-orange-400 scale-100' : ''}`} key={index}>
                                        <h2 key={index} className="font-semibold text-lg whitespace-pre-wrap text-left" onClick={() => handleCreateArticle(headline_text)}>
                                            {headline_text}
                                        </h2>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            
                            {/* right column */}
                            <div className='col-span-1 hidden md:block'>
                                <h1 className="text-3xl text-center font-bold pb-4">Personalized Fun with Just a Click of a Button!</h1>
                                <hr className='border-2 rounded-lg border-slate-900'/>
                                <h1 className="text-2xl text-start py-4">Quidam is the perfect entertaining gift for you and your friends. 
                                Type in your names and it'll create personalized articles for some good-natured humor. 
                                Plus, you can generate a quirky newsletter to share the laughs with everyone!"</h1>
                                <div>
                                    <img src='/img/ai_news_1.png' alt="Newspaper" className="max-w-full h-auto rounded-lg object-contain" />
                                </div>
                            </div>
                        </div>
                    <div>
                        {/* article response */}
                        <div className='p-8 md:p-16'>
                            <div>
                                {
                                    responseArticle.length > 0 && (
                                        <div>
                                            <h1 className='p-0 md:p-4 font-bold font-nyt_headline text-3xl md:text-7xl text-center'>We bring you {searchQuery}</h1>
                                            <hr className='border-2 rounded-lg border-slate-900'/>
                                        </div>
                                    )
                                }
                                <h2 className="font-semibold text-lg p-4 whitespace-pre-wrap text-left">{responseArticle}</h2>
                            </div>
                            <div>
                                {
                                    responseArticle.length > 0 && (
                                    <button className='p-4 rounded-lg bg-orange-300 text-xl font-semibold hover:bg-orange-400 hover:cursor-pointer hover:scale-105'>
                                            <Link to="/all_headlines">Generate More!</Link>
                                    </button>
                                        
                                    )
                                }
                            </div>
                            {/* Button for styling comment out  */}
                            {/* <button className='p-4 rounded-lg bg-orange-100 hover:bg-orange-200'>
                                <Link to="/all_headlines">Generate More!</Link>
                            </button> */}
                        </div>
                    </div>
        </div>
        </div>
    )
}
