import {useState} from 'react' 
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 

import NavBar from './components/NavBar';
import LandingPage from './components/LandingPage';
import AllHeadlines from './components/AllHeadlines';

function App() {
  const [search_query, setSearchQuery] = useState<string>("");
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={<LandingPage searchQuery={search_query} setSearchQuery={setSearchQuery} />}
          />
          <Route 
            path="/all_headlines" 
            element={<AllHeadlines searchQuery={search_query} setSearchQuery={setSearchQuery} />} 
          />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
