import React from 'react';
import { Link } from 'react-router-dom';

export default function NavBar(){
    return(
        <header className="grid grid-cols-3 w-full sticky top-0 font-bold text-xl md:text-2xl h-20 bg-white border-b-4 border-black">
            <div className="col-span-1 grid p-5"> 
                <Link to="/">Quidam</Link>
            </div> 
            <div className='col-span-1 grid place-items-center'>
                <hr/>
                <img src='/img/Logo.png' alt="logo" className="h-20 md:h-36"/>
            </div>
            <div className="col-span-1 p-5"> 
                    <Link to="/all_headlines">Generate</Link>
            </div>
        </header>
    )
}