import { SetStateAction, useState } from "react";
import { LandingPageProps } from "../interfaces/Searches";

interface headlinesDict {
  [key: string]: string;
}

export default function AllHeadlines({
  searchQuery,
  setSearchQuery,
}: LandingPageProps) {
  const [headlines, setHeadlines] = useState<string[]>([]);
  const [selectedHeadlines, setSelectedHeadlines] = useState<string[]>([]);
  const [showNewspaper, setShowNewspaper] = useState(false);
  const handleInputChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchQuery(e.target.value);
  };
  const [responseArticle, setResponseArticle] = useState<headlinesDict>({});
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setHeadlines([]);
    const url = `https://quidam-a6niq4wkvq-uc.a.run.app/getHeadlines?name=${searchQuery}`;
    // const url = `http://localhost:5005/getHeadlines?name=${searchQuery}`;

    let chunks = "";
    for (let i = 0; i < 3; i++) {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: searchQuery }),
      });

      const decoder = new TextDecoder();

      // Set up a new ReadableStream to read the response body
      const reader = response.body!.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoded = decoder.decode(value);
        chunks += decoded;
        // here, we split the response into an array of strings, each of which is a headline
        const headlines = chunks.split("\n");
        setHeadlines(headlines);
      }
      chunks += "\n";
    }

  };
  

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the newline
      handleSubmit({ preventDefault: () => {} }); // Manually trigger form submission
    }
  };

  const highlightArticle = async (chosenHeadline: string) => {
    if (selectedHeadlines.includes(chosenHeadline)) {
      setSelectedHeadlines(
        selectedHeadlines.filter((e) => e !== chosenHeadline)
      );
    } else {
      setSelectedHeadlines(selectedHeadlines.concat(chosenHeadline));
    }
  };

  const fetchAllArticles = async () => {
    setShowNewspaper(true);
    const fetchPromises = selectedHeadlines.map(handleCreateArticle);
    await Promise.all(fetchPromises);
  };

  const handleCreateArticle = async (headline: string) => {
    const url = `https://quidam-a6niq4wkvq-uc.a.run.app/getFullArticle?headline=${headline}&context=${searchQuery}`;
    // const url = `http://localhost:5005/getFullArticle?headline=${chosenHeadline}&context=${searchQuery}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ headline: headline }),
    });
    const decoder = new TextDecoder();

    // Set up a new ReadableStream to read the response body
    const reader = response.body!.getReader();
    // let chunks = "";

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const decoded = decoder.decode(value);
    //   chunks += decoded;
      setResponseArticle((prevArticles) => ({
        ...prevArticles,
        [headline]: (prevArticles[headline] || "") + decoded,
      }));
    }

  };

  return (
    <div className="px-8 md:px-16 pt-10 font-serif">
        <div className="text-center">
          <h1 className="text-4xl md:text-8xl font-nyt_headline">All Headlines</h1>
      </div>

      <div id="Generate" className="pt-5">
        <form onSubmit={handleSubmit} className="p-4">
          <div>
            <label htmlFor="text" className="block font-bold text-xl mb-3">
              Who should we de-quidam-ify?
            </label>
            <div className="flex place-content-center">
              <textarea
                id="text"
                name="text"
                value={searchQuery}
                placeholder="Amr Kayid and Sylvie Shi and Siddhartha Rao Kamalakara"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="w-2/3 p-1.5 font-semibold text-lg bg-transparent outline-none border-b-4 rounded-sm"
                required
                rows={1} // You can adjust the number of rows as needed
              ></textarea>
              <button type="submit" className="bg-transparent rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="text-center"></div>
        </form>
        <div className="grid grid-cols-1 md:grid-cols-3">
          {headlines.length > 0 &&
            headlines.map((headline_text, index) => (
              <div
                className={`col-span-1 hover:bg-orange-200 m-2 md:m-4 text-sm border border-orange-300 rounded-lg p-2 ${
                  selectedHeadlines.includes(headline_text)
                    ? "bg-orange-300"
                    : ""
                }`}
                key={index}
              >
                <h2
                  key={index}
                  className="font-semibold font-serif text-md md:text-lg whitespace-pre-wrap text-left"
                  onClick={() => highlightArticle(headline_text)}
                >
                  {headline_text}
                </h2>
              </div>
            ))}
        </div>
        <div className="pt-5">
          {selectedHeadlines.length >= 1 ? (
            <button
              className="bg-orange-800 hover:bg-blue-700 text-white font-bold text-xl py-2 px-4 rounded-lg shadow-lg"
              onClick={() => fetchAllArticles()}
            >
              Buy newspaper!
            </button>
          ) : (
            "Please select at least 3 headlines."
          )}
          {/* {selectedHeadlines.length >= 3 ? 
                    <a className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-xl py-2 px-4 rounded-lg shadow-lg" href='https://buy.stripe.com/28o7vDbcHgTA4Ra6op' target="_blank" rel="noreferrer" onClick={() => handleCreateArticle()}>Buy newspaper!</a> : 
                    'Please select at least 3 headlines.'} */}
        </div>
        <div className="m-16 bg-slate-100 p-12 ">
          {/* Header */}
          {showNewspaper && (
            <div>
              <div className="grid grid-cols-6 items-center">
                <div className="col-span-1 border-2 p-4 m-1">
                  <h1>"All the News We Feel Like Printing"</h1>
                </div>
                <div className="col-span-4">
                  <h1 className="p-0 md:p-4 font-bold font-nyt_headline text-3xl md:text-7xl text-center">
                    The Quidam News
                  </h1>
                </div>
                <div className="col-span-1">
                  <h1 className="font-bold">Toronto</h1>
                </div>
              </div>
              <hr className="border-1 rounded-lg border-slate-900" />
              <div>
                blrblr
              </div>
              <hr className="border-2 rounded-lg border-slate-900" />
            </div>
          )}

          {/* articles being generated */}
          <div className="grid grid-cols-3 grid-flow-Z gap-4">
            {Object.entries(responseArticle).map(
              ([headline, content], index) => (
                <div key={index} className="col-span-1">
                  <h2 className=" text-md py-4 font-bold">{headline}</h2>
                  <hr className=" border-1 rounded-lg border-slate-900" />
                  <p className="py-4 text-md whitespace-pre-wrap text-start">{content}</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
